import React from "react";
import styled from "styled-components";
import ColorPlate from './ColorPlate'

interface IModalProps {
    colorChangeFunction: (color:string) => void;
    closeModal() : void;
}

const ColorChangeModal: React.FC<IModalProps> = (props) => {
    return (
        <>
            <ModalWrapper>
                <ColorPlate 
                    color="red"
                    colorChangeFunction={props.colorChangeFunction}
                    closeModal={props.closeModal}
                />
                <ColorPlate 
                    color="orange"
                    colorChangeFunction={props.colorChangeFunction}
                    closeModal={props.closeModal}
                />
                <ColorPlate 
                    color="yellow"
                    colorChangeFunction={props.colorChangeFunction}
                    closeModal={props.closeModal}
                />
                <ColorPlate 
                    color="green"
                    colorChangeFunction={props.colorChangeFunction}
                    closeModal={props.closeModal}
                />
                <ColorPlate 
                    color="blue"
                    colorChangeFunction={props.colorChangeFunction}
                    closeModal={props.closeModal}
                />
                <ColorPlate 
                    color="purple"
                    colorChangeFunction={props.colorChangeFunction}
                    closeModal={props.closeModal}
                />  
            </ModalWrapper>
        </>
    )
};

export default ColorChangeModal;

const ModalWrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    border-radius: 4px;
    max-height: 500px;
    background-color: white;
    width: 70%;
`;