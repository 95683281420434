import React from 'react';
import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import useColor from '../hooks/useColor';
import { ReactComponent as PersonaIcon } from '../assets/FollowerIcon.svg';
import { ReactComponent as HandIcon } from '../assets/HandCountIcon.svg';
import { ReactComponent as PlayerListIcon } from '../assets/Nav-PlayersIcon.svg';
import { ReactComponent as DiscardIcon } from '../assets/Nav-DiscardIcon.svg';
interface Props {
    //    handCount: number;
}

const NavBar: React.FC<Props> = (props) => {
    const [, primary,] = useColor("none");

    return (
        <Bar>
            <NavigationLink to='/game'>
                <IconContainer>
                    <HandIcon fill={primary} />
                </IconContainer>
                <IconText>
                    Hand
                </IconText>
            </NavigationLink>
            <NavigationLink to='/discard'>
                <IconContainer>
                    <DiscardIcon fill={primary} />
                </IconContainer>
                <IconText>
                    Discard
                </IconText>
            </NavigationLink>
            <NavigationLink to='/playerlist'>
                <IconContainer>
                    <PlayerListIcon fill={primary} />
                </IconContainer>
                <IconText>
                    Players
                </IconText>
            </NavigationLink>
            <NavigationLink to='/profile'>
                <IconContainer>
                    <PersonaIcon fill={primary} width={25} style={{ marginBottom: "-10px" }} />
                </IconContainer>
                <IconText>
                    Persona
                </IconText>
            </NavigationLink>
        </Bar>
    );
};

export default NavBar;

const Bar = styled.nav`
    height: 80px;
    width: 100vw;
    display:flex;
    align-self: flex-end;
    background-color: black;
    justify-content: space-between;
    padding: 0.5rem calc((100vw - 1000px) / 2);
    position: sticky;
    bottom: 0px;
`
const NavigationLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    margin: 0px 5px 0px 5px;

    &.active {
        background-color: #15cdfc;
        border-radius: 15px;
        text-decoration:none;
        transition: .5s;
    }
`
const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
`
const IconText = styled.div`
    font-family: Poppins;
    font-size: 13px;
    color:white;
`


