import React from 'react';
import parse from 'html-react-parser';
import { ICard, Player } from '../helpers/interfaces';
import styled from 'styled-components';
import OOUButton from './OOUButton'
import useColor from '../hooks/useColor';
import CultIcon from '../components/CultIcon';
import './Modal.css'

interface Props {
  card: ICard | undefined;
  players: Array<Player> | undefined;
  discardFunction(): void;
  closeModal(): void;
  tradeClicked?: () => void;
  tradeInProgress?: boolean;
}

const CardDetail: React.FC<Props> = (props) => {
  const [, primary,] = useColor(props?.card?.color.toLowerCase());

  const TradeButtonSelector = () => {
    if (!props.tradeInProgress) {
      return (
        <>
          <OOUButton
            text={"Trade"}
            onpress={props.tradeClicked!}
            color={props?.card?.color.toLowerCase()} />

          <OOUButton
            text="Discard"
            onpress={() => {
              props?.discardFunction();
              props?.closeModal();
            }}
            color={props?.card?.color.toLowerCase()} />
        </>
      );
    } else {
      return (<></>);
    };
  };

  return (
    <>
      <CardWrapper style={{ background: `linear-gradient(0deg, rgb(255, 255, 255) 10%, ${primary} 100%)` }} >
        <CardDetailContainer>
          <TitleContainer>
            <TitleText>
              {props?.card?.title && parse(props.card.title)}
            </TitleText>
            <CultIcon color={props?.card?.color.toLowerCase()} iconSize={25} />
          </TitleContainer>
          <NewDivider style={{ backgroundColor: primary }} />
          <CardText>
            {props?.card?.text && parse(props.card.text)}
          </CardText>
          <ButtonContainer>
            <TradeButtonSelector />
          </ButtonContainer>
        </CardDetailContainer>
      </CardWrapper>
    </>
  );
};

export default CardDetail;

const TitleText = styled.div`
  margin: auto;
  font-size: 19px;
  font-weight: 1000;
  letter-spacing: 1px;
  font-family: Poppins;
  flex-wrap: wrap;
  display:flex;
  justify-content: center;
  align-items:center;
  text-align: center;
`;

const CardText = styled.div`
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  margin: 0px 10px 25px 20px;
  letter-spacing: .5px;
  font-family: Poppins;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
  /* flex-wrap: wrap; */
`;

const CardWrapper = styled.div`
  width: 95vw;
  border-radius: 15px;
  justify-content: center;
  align-items:center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  max-width:300px;
`;

const CardDetailContainer = styled.div`
  background-color: light;
  flex-direction: column;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  width:100%;
`;
const NewDivider = styled.div`
  width: 85%;
  margin: 15px 0;
  height:3px;
  align-self: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-family: Poppins;
  letter-spacing: .75px;
`;
