import React, {useContext } from 'react';
import styled from 'styled-components';
import {SnackContext, SnackContextType} from '../helpers/SnackContext';

const SnackBar: React.FC = () => {
    
    const {snack} = useContext<SnackContextType>(SnackContext);

    return (
        <Wrapper display={snack.display}>
            <OOUText>
                {snack.message}
            </OOUText>
            <ButtonContainer>
                {snack.interactable ? 
                <OOUText 
                    button 
                    onClick={()=> snack.onAccept()}
                >
                    Accept
                </OOUText> :
                null
                }
                <OOUText 
                    style={{color: 'orangered'}}
                    button 
                    onClick={()=> snack.onClose()}
                >
                    {snack.interactable ? "Deny" : "Dismiss"}
                </OOUText>
            </ButtonContainer>
        </Wrapper>
    );
};

export default SnackBar;

interface ISnack{
    display:boolean;
}

const Wrapper = styled.div<ISnack>`

    display: ${(props) => (props.display ? "flex" : "none")};
  
    position: fixed;
    top: 5px;
    background-color: blanchedalmond;
    height: 50px;
    max-width: 95%;
    align-items: center;
    margin-left: 10px;
    justify-content: center;
    padding: 0px 5px 0px 5px;
    border-radius: 5px;
    animation: snackAppear .5s ease-in;
    z-index: 1000000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

    @keyframes snackAppear{
        0%{
            transform: translateY(-300%);
            opacity:0;
        }
        80%{
            transform:translateY(70%);
            opacity:1;
        }
        100%{ 
            transform: translateY(0%);
            opacity:1
        }
    }
`;


const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`
interface IText{
    button?:boolean;
}
const OOUText = styled.div<IText>`
    margin: 0px 5px 0px 5px;
    font-weight: ${(props) => (props.button ? "1000" : "500")};
    font-size:  ${(props) => (props.button ? "15px" : "13px")};
    letter-spacing: ${(props) => (props.button ? ".5px" : ".3px")};
    line-height: 20px;
    font-family: Poppins;
`;
