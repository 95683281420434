// import React, { createContext, useContext, useState } from 'react'

// export interface ISnack {
//     display: boolean,
//     message: string,
//     interactable: boolean,
//     onAccept: Function
// }

// export interface ISnackContext {
//     snack: ISnack[],
//     setSnack: React.Dispatch<React.SetStateAction<ISnack[]>>
// }

// export const SnackContext = createContext<ISnackContext>({
//     snack:[{display: false, message: "whatever2", interactable: false, onAccept: ()=> {}}],
//     setSnack: () => {}
// });

// export const SnackProvider = (props:any) => {
//     const [snack, setSnack] = useState<ISnack[]>([{
//         display:false, 
//         message: '',
//         interactable: false,
//         onAccept: ()=> {}
//     }]);

//     return(
//         <SnackContext.Provider value={{snack, setSnack}}>
//             {props.children}
//         </SnackContext.Provider>

//     );
// }

import React, { createContext, useState } from 'react'

 export interface ISnack {
    display: boolean,
    message: string,
    interactable: boolean,
    onAccept: Function,
    onClose: Function
}

export type SnackContextType = {
  snack: {
    display: boolean,
    message: string,
    interactable: boolean,
    onAccept:Function,
    onClose: Function
  }
  setSnack: (snack: {
    display: boolean,
    message: string,
    interactable: boolean,
    onAccept:Function,
    onClose:Function
  }) => void;
} 

export const SnackContext = createContext<SnackContextType>({
    snack:{display: false, message: "whatever2", interactable: false, onAccept: ()=> {}, onClose: ()=> {}},
    setSnack: () => {}
});

export const SnackProvider = (props:any) => {
    const [snack, setSnack] = useState<ISnack>({
        display:false, 
        message: '',
        interactable: false,
        onAccept: ()=> {},
        onClose: ()=> {}
    });

    return(
        <SnackContext.Provider value={{snack, setSnack}}>
            {props.children}
        </SnackContext.Provider>
    );
}