import React from 'react';
import RedIcon from '../assets/RedIcon.svg';
import RedIconBLK from '../assets/RedCultIconBlk.svg';
import OrangeIcon from '../assets/OrangeIcon.svg';
import OrangeIconBLK from '../assets/OrangeCultIconBlk.svg';
import YellowIcon from '../assets/YellowIcon.svg';
import YellowIconBLK from '../assets/YellowCultIconBlk.svg';
import GreenIcon from '../assets/GreenIcon.svg';
import GreenIconBLK from '../assets/GreenCultIconBlk.svg';
import BlueIcon from '../assets/BlueIcon.svg';
import BlueIconBLK from '../assets/BlueCultIconBlk.svg';
import PurpleIcon from '../assets/PurpleIcon.svg';
import PurpleIconBLK from '../assets/PurpleCultIconBlk.svg';
import OOUIcon from '../assets/OOUIcon.svg';

interface Props {
  color: string | undefined;
  iconSize: number | undefined;
  black?: boolean;
}

const CultIcon: React.FC<Props> = (props) => {

    let ICON;

    switch (props?.color?.toLowerCase()){
        case "red":
            if(props.black){
                ICON = <img style={{width: props.iconSize! * .8, display: "", justifySelf: 'flex-end'}}src={RedIconBLK} alt={'Red Cult Icon'}/>;
                break;
            }
            ICON = <img style={{width: props.iconSize! * .8, display: "", justifySelf: 'flex-end'}}src={RedIcon} alt={'Red Cult Icon'}/>;
            break;
        case "orange":
            if(props.black){
                ICON = <img style={{width: props.iconSize, display: "", justifySelf: 'flex-end'}}src={OrangeIconBLK} alt={'Orange Cult Icon'}/>;
                break;
            }
            ICON = <img style={{width: props.iconSize, display: "", justifySelf: 'flex-end'}}src={OrangeIcon} alt={'Orange Cult Icon'}/>;
            break;
        case "yellow":
            if(props.black){
                ICON = <img style={{width: props.iconSize, display: "", justifySelf: 'flex-end'}}src={YellowIconBLK} alt={'Yellow Cult Icon'}/>;
                break;
            }
            ICON = <img style={{width: props.iconSize, display: "", justifySelf: 'flex-end'}}src={YellowIcon} alt={'Yellow Cult Icon'}/>;
            break;
        case "green":
            if(props.black){
                ICON = <img style={{width: props.iconSize, display: "", justifySelf: 'flex-end'}}src={GreenIconBLK} alt={'Green Cult Icon'}/>;
                break;
            }
            ICON = <img style={{width: props.iconSize, display: "", justifySelf: 'flex-end'}}src={GreenIcon} alt={'Green Cult Icon'}/>;
            break;
        case "blue":
            if(props.black){
                ICON = <img style={{width: props.iconSize, display: "", justifySelf: 'flex-end'}}src={BlueIconBLK} alt={'Blue Cult Icon'}/>;
                break
            }
            ICON = <img style={{width: props.iconSize, display: "", justifySelf: 'flex-end'}}src={BlueIcon} alt={'Blue Cult Icon'}/>;
            break;
        case "purple":
            if(props.black){
                ICON = <img style={{width: props.iconSize! * .8, display: "", justifySelf: 'flex-end'}}src={PurpleIconBLK} alt={'Purple Cult Icon'}/>;
                break;
            }
            ICON = <img style={{width: props.iconSize! * .8, display: "", justifySelf: 'flex-end'}}src={PurpleIcon} alt={'Purple Cult Icon'}/>;
            break;
        default:
            ICON = <img style={{width: props.iconSize, display: "", justifySelf: 'flex-end'}}src={OOUIcon} alt={'OOU Icon'}/>;
            break;
    } 
        

    return (
        <>
            {ICON}
        </>
    );
};

export default CultIcon;