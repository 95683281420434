import React from 'react'
import styled from 'styled-components';
import useColor from '../hooks/useColor';
import CultIcon from '../components/CultIcon';
import { Player } from '../helpers/interfaces';
import { ReactComponent as Crown } from '../assets/NamePlateCrown.svg';
import { ReactComponent as HandCount } from '../assets/HandCountIcon.svg';

interface Props {
    player: Player | undefined;
    tradeFunction?: (playerTo: string) => void;
    closeModal?: () => void;
    order?: number;
}

const NamePlate: React.FC<Props> = (props) => {

    const [, primary, dark] = useColor(props?.player?.color.toLowerCase());

    const tradeClicked = () => {
        if (props.tradeFunction) {
            props.tradeFunction(props.player?.playerID!);
            props.closeModal!;
        }
    }

    return (
        <NamePlateWrapper onClick={tradeClicked} order={props.order}>
            <LeftIconContainer
                style={{ backgroundColor: dark }}
            >
                <CultIcon color={props?.player?.color.toLowerCase()} iconSize={25} />
            </LeftIconContainer>
            <NameContainer style={{ backgroundColor: primary }}>
                <NameText
                    color={props.player?.color.toLowerCase()}
                >
                    {props.player?.playerID.toUpperCase()}
                </NameText>
                <RightIconContainer>
                    {props.player?.leader === "true" ? <Crown width={25} style={{marginRight: 5}}/> : <div/>}
                    <HandCountContainer style={{position:"relative"}}>
                        <HandCount fill={dark}/>
                        <NameText style={{position:"absolute"}}>
                            {props.player?.cardDetails.length}
                        </NameText>
                    </HandCountContainer>
                </RightIconContainer>
            </NameContainer>
        </NamePlateWrapper>
    );
};

export default NamePlate;

interface IWrapper {
    order?: number;
}
const NamePlateWrapper = styled.div<IWrapper>`
    display: flex;
    margin: 5px;
    background-color: transparent;
    width: 80%;
    max-width: 400px;

    /* animation: dropIn 1s ease both;
    animation-delay: calc(${(props)=> props.order!} * 50ms);

    @keyframes dropIn{
        0%{transform: scale(.5);
            opacity:0}
        50%{transform:scale(1.2);
            opacity:1}
        100%{ transform: scale(1);
            opacity:1}
    } */
`
const LeftIconContainer = styled.div`
    display:flex;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    height: 35px;
    width: 35px;
    justify-content: center;
    align-items: center;
`
const RightIconContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 35%;
    align-items: center;
`
const NameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
    display: flex;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    height: 35px;
    width: 100%;
`
const HandCountContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
`

interface IText {
    color?: string;
}
const NameText = styled.div<IText>`
    color: ${(props) => (props.color === "none" ? "" : "white")};
    font-weight: 600;
    letter-spacing: .6px;
    font-family: Poppins;
`