import React, { useState, useEffect } from 'react';
import { Server, Player, ICard } from '../helpers/interfaces';
import MiniCard from "./MiniCard";
import styled from 'styled-components';

interface Props {
  username:string;
  serverDetail:Server;
  handleSelectedCard?:Function;
}

const CardContainer:React.FC<Props> = (props) => {
  const [selectedCard, setSelectedCard] = useState<ICard>();

  useEffect(() => {
    if(props.handleSelectedCard && selectedCard) {
      props.handleSelectedCard(selectedCard);
      setSelectedCard(undefined);
    }
  }, [selectedCard]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {props.serverDetail?.players
      .filter((player: Player) => player.playerID === props.username)
      .map((player: Player, index: number) => (
        <CardContainerWrapper key={index}>
          <CardGrid>
            {player.cardDetails?.map((card: ICard, index: number) => (
              <MiniCard 
                card={card} 
                click={() => setSelectedCard(card)}
                players={props.serverDetail?.players}
                order={index}
                key={index}
              />
            ))}
          </CardGrid>
        </CardContainerWrapper>
      ))}
    </>
  );
};

export default CardContainer;

const CardContainerWrapper = styled.div`
  width: 100vw;
  background-color: #E5E5E5;
  display: flex;
  justify-content: center;
  align-items:flex-start;
  height: calc(100vh - 120px);
`
const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  @media screen and (max-width:360px){
    grid-template-columns: 1fr;
    
  }
`

