import React from 'react';
import styled from 'styled-components';
import InfoIcon from '../assets/InfoIcon.svg';
import Modal from 'react-bootstrap/Modal';
import InfoButtonsModal from './InfoButtonsModal';
import AppendixModal from './AppendixModal';
import TutorialModal from './TutorialModal';
import CreditsModal from './CreditsModal';
import { ICard, Tutorial } from '../helpers/interfaces';

interface Props {
  appendix: Array<ICard>;
  tutorials: Array<Tutorial>;
  time: number | undefined;
}

const TopBar: React.FC<Props> = (props) => {
  const [infoModal, setInfoModal] = React.useState<boolean>(false);
  const [appendixModal, setAppendixModal] = React.useState<boolean>(false);
  const [tutorialModal, setTutorialModal] = React.useState<boolean>(false);
  const [creditsModal, setCreditsModal] = React.useState<boolean>(false);

  function openAppendixModal() {
    setInfoModal(false);
    setAppendixModal(true);
  }
  function openTutorial() {
    setInfoModal(false);
    setTutorialModal(true);
  }
  function openCredits() {
    setInfoModal(false);
    setCreditsModal(true);
  }

  return (
    <>
      {/* Info Buttons Modal */}
      <Modal
        show={infoModal}
        onHide={() => setInfoModal(false)}
        centered
      >
        <InfoButtonsModal
          openAppendix={openAppendixModal}
          openTutorial={openTutorial}
          openCredits={openCredits}
        />
      </Modal>
      {/* Appendix Modal */}
      <Modal
        show={appendixModal}
        onHide={() => setAppendixModal(false)}
        centered
      >
        <AppendixModal
          appendix={props.appendix}
        />
      </Modal>
      {/* Tutorial Modal */}
      <Modal
        show={tutorialModal}
        onHide={() => setTutorialModal(false)}
        centered
      >
        <TutorialModal
          tutorials={props.tutorials}
          closeModal={() => setTutorialModal(false)}
        />
      </Modal>
      <Modal
        show={creditsModal}
        onHide={() => setCreditsModal(false)}
        centered
      >
        <CreditsModal />
      </Modal>
      <Wrapper>
        <Bar>
          <h6 style={{marginRight: 25}}>
            {props.time && props.time > 0
              ? <div>Time Remaining: {props.time}</div>
              : <div>Time Remaining: Game Over</div>}
          </h6>
          <img
            src={InfoIcon}
            alt={"Info Icon"}
            onClick={() => setInfoModal(true)}
          />
        </Bar>
      </Wrapper>
    </>
  );
};

export default TopBar;
const Wrapper = styled.div`
    position: sticky;
    top:0;
`;

const Bar = styled.div`
  width: 100vw;
  display: flex;
  height: 40px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10px 20px;
  background-color: #E5E5E5;
`;



