import React, { useState } from 'react';
import useColor from '../hooks/useColor';
import ColorChangeModal from './ColorChangeModal'
// import Button from 'react-bootstrap/Button';
import fetchAPI from '../helpers/api';
import { Server, Player } from '../helpers/interfaces';
import { CHANGECOLOR_URL, LEADERCOLOR_URL } from '../helpers/constants';
import styled from 'styled-components';
import { ReactComponent as Follower } from '../assets/FollowerIcon.svg';
import { ReactComponent as Leader } from '../assets/LeaderIcon.svg';
import CultIcon from './CultIcon'
import Modal from 'react-bootstrap/Modal'
import './Modal.css'

interface Props {
  currentPlayer: Player | undefined;
  username: string;
  serverDetail: Server;
  discardDisplay: boolean;
}

const Misc: React.FC<Props> = (props) => {
  const [, primary,] = useColor(props?.currentPlayer?.color.toLowerCase());
  // const [discardDisplay, setDiscardDisplay] = useState<boolean>(props.discardDisplay);
  const [colorChangeModal, setColorChangeModal] = useState<boolean>(false);

  const player = props.serverDetail?.players.filter((player: Player) => player.playerID === props.username);

  const changeColor = async (newColor: string) => {
    await fetchAPI(CHANGECOLOR_URL(props.username, newColor));
  }

  const changeLeader = async () => {
    if (player[0].leader === "true") {
      await fetchAPI(LEADERCOLOR_URL(props.username, false));
    } else {
      await fetchAPI(LEADERCOLOR_URL(props.username, true));
    }
  }

  // const changeDiscardMode = async () => {
  //   await fetchAPI(CHANGEDISCARD_URL(!discardDisplay));
  //   setDiscardDisplay((prevMode) => { return (!prevMode); })
  // }

  return (
    <>
      <Modal
        show={colorChangeModal}
        onHide={() => setColorChangeModal(false)}
        centered
      >
        <ColorChangeModal
          colorChangeFunction={changeColor}
          closeModal={() => setColorChangeModal(false)}
        />
      </Modal>
      <Wrapper>
        <TopBar>
          <CultIcon
            color={props?.currentPlayer?.color.toLowerCase()}
            iconSize={50}
          />
        </TopBar>
        <UsernameText>
          {props.currentPlayer?.playerID.toUpperCase()}
        </UsernameText>
        <IconContainer>
          {props.currentPlayer?.leader === "true" ? <Leader fill={primary} /> : <Follower fill={primary} />}
        </IconContainer>
        <ColorButton onClick={() => setColorChangeModal(true)}>
          <ButtonText>
            CHANGE COLOR
          </ButtonText>
        </ColorButton>
        <FormContainer>
          <RadioContainer>
            <RadioLabel>
              Follower
            </RadioLabel>
            <input
              type="radio"
              name="follower-leader"
              value="option1"
              checked={props.currentPlayer?.leader === "false"}
              onChange={changeLeader}
              className="radio"
            />
          </RadioContainer>
          <RadioContainer>
            <RadioLabel>
              Leader
            </RadioLabel>
            <input
              type="radio"
              name="follower-leader"
              value="option1"
              checked={props.currentPlayer?.leader === "true"}
              onChange={changeLeader}
            />
          </RadioContainer>
        </FormContainer>
        {/* <div>
          <div>
             {discardDisplay ? 'Discards are being displayed to players' : 'Discards are hidden'}
          </div>
          <div>
            <Button variant="danger" style={{ marginTop: "16px", marginBottom: "16px", marginLeft: "20px" }} onClick={changeDiscardMode}>Change Discard Mode</Button>
          </div>
        </div> */}
      </Wrapper>
    </>
  );
};

export default Misc;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 120px);
  background-color: #E5E5E5;
`
const RadioLabel = styled.label`
    margin: 5px;
    font-weight: 600;
    font-family: Poppins;
`
const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
`
const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 5px 0px 5px;
`
const IconContainer = styled.div`
  width: 180px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0px 0px 15px 0px;
`
const UsernameText = styled.div`
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 1.5px;
  font-family: Poppins;
`
const ColorButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  width: 215px;
  height: 40px;
  border-radius: 5px;
`
const ButtonText = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: Poppins;
`
const TopBar = styled.div`
  width: 100%;
  display: flex;
  height: 80px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  margin-top: 10px;
`