const LOCAL_HOST = "http://localhost:5000";
const REMOTE_HOST = process.env.REACT_APP_STAGING_API_URL ? 'https://nicenux-oneofus.herokuapp.com' : 'https://oneofus.onrender.com';
export const SERVER_URL = process.env.NODE_ENV === 'development' ? LOCAL_HOST : REMOTE_HOST;

export const CARDLIST_URL = () => `${SERVER_URL}/cardlist`;
export const ADDPLAYER_URL = (id: string, leader: boolean) => `${SERVER_URL}/addplayer/${id}/${leader}`;
export const TRADE_URL = (idFrom:string, idTo:string, card:string) => `${SERVER_URL}/trade/${idFrom}/${idTo}/${card}`;
export const UPDATETRADE_URL = (userId:string, card:string) => `${SERVER_URL}/updateTrade/${userId}/${card}`;
export const INITIATETRADE_URL = (idFrom: string, idTo: string, card: string) => `${SERVER_URL}/initiatetrade/${idFrom}/${idTo}/${card}`;
export const CONFIRMTRADE_URL = (id1: string, id2: string, card1: string, card2: string) => `${SERVER_URL}/confirmtrade/${id1}/${id2}/${card1}/${card2}`;
export const CANCELTRADE_URL = (id: string) => `${SERVER_URL}/canceltrade/${id}`;
export const FIRSTACCEPTTRADE_URL = (id: string) => `${SERVER_URL}/firstaccepttrade/${id}`;
export const TRADECARDSELECTED_URL = (id: string, card: string) => `${SERVER_URL}/tradecardselected/${id}/${card}`;
export const CHANGECOLOR_URL = (id: string, color: string) => `${SERVER_URL}/changecolor/${id}/${color}`;
export const LEADERCOLOR_URL = (id: string, leader: boolean) => `${SERVER_URL}/changeleader/${id}/${leader}`;
export const PUTDISCARD_URL = (id: string, card: string) => `${SERVER_URL}/putdiscard/${id}/${card}`;
export const TAKEDISCARD_URL = (id: string, card: string) => `${SERVER_URL}/takediscard/${id}/${card}`;
export const GAMESETTINGS_URL = (gameid: number) => `${SERVER_URL}/gamesettings/${gameid}`;
export const TUTORIAL_URL = () => `${SERVER_URL}/alltutorials`;
export const TAKERANDOMDISCARD_URL = (id: string) => `${SERVER_URL}/takerandomdiscard/${id}`;
export const CHANGEDISCARD_URL = (discardDisplay: boolean) => `${SERVER_URL}/changediscardmode/1/${discardDisplay}`;
