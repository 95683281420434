async function fetchAPI(URL:string) {
  const res = await fetch(URL);
  const data = await res.json();
  if (!res.ok) {
    const message = `An error has occured: ${res.status}`;
    alert("internal error - retry:" + message)
    throw new Error(message);
  }
  return data;
}

export default fetchAPI;