import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import DiscardModal from './DiscardModal';
import { Server } from '../helpers/interfaces';
import { TAKEDISCARD_URL, TAKERANDOMDISCARD_URL } from '../helpers/constants';
import fetchAPI from '../helpers/api';
import {ReactComponent as DiscardImage} from '../assets/DiscardPileIcon.svg';
import {ReactComponent as DrawImage} from '../assets/DrawRandom.svg';
import {ReactComponent as PeekImage} from '../assets/PeekIcon.svg';
import styled from 'styled-components';

interface Props {
  username: string;
  serverDetail: Server;
  discardDisplay: boolean;
};

const Discard: React.FC<Props> = (props) => {
  const [discardModalOpen, setDiscardModalOpen] = useState<boolean>(false);
  const cardNumInMyHand = props.serverDetail.players.find(p => p.playerID === props.username)?.cardDetails.length ?? 0;

  const takeDiscard = async (cardID: string) =>{
    await fetchAPI(TAKEDISCARD_URL(props.username, cardID));
  } 
  const takeRandomDiscard = async () => await fetchAPI(TAKERANDOMDISCARD_URL(props.username));

  return (
    <>
      <Modal
        show={discardModalOpen} 
        onHide={() => setDiscardModalOpen(false)} 
        centered 
        scrollable 
      >
        <Modal.Body>
          <DiscardModal 
            discardDetails={props.serverDetail?.discardDetails}
            discardFunction={takeDiscard}
            closeModal={()=> setDiscardModalOpen(prev=>!prev)}
            disabled={cardNumInMyHand >= 3}
          />
        </Modal.Body>
      </Modal>
      <DiscardWrapper>
        <DiscardImage style={{margin:"25px 10px 25px 10px"}}/>
        <ButtonContainer>
          <DiscardButton disabled={cardNumInMyHand >= 3} onClick={takeRandomDiscard}>
            <DrawImage/>
            <ButtonText>
              DRAW
            </ButtonText>
          </DiscardButton>
          { props.discardDisplay ? 
            <DiscardButton onClick={()=>setDiscardModalOpen(true)}>
              <PeekImage/>
              <ButtonText>
                PEEK
              </ButtonText>
            </DiscardButton> :
            ""
          }
        </ButtonContainer>
      </DiscardWrapper>
    </>
  );
};

export default Discard;

const DiscardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-Items: center;
  background-color: #E5E5E5;
  height: calc(100vh - 120px);

  /* @media screen and (max-width:400px){
    height: calc(100vh - 83px);
  } */
`;
const DiscardButton = styled.button`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color:#252222;
  border-radius: 10px;
  width: 150px;
  padding-bottom: 15px;
  transition: all 1000ms ease-out;

  :active{
    transform: scale(.7);
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 110px;
  margin: 10px 0px 10px 0px;
`;
const ButtonText = styled.div`
  color: white;
  font-weight: 700;
  letter-spacing: 1px;
  font-family:Poppins;
  font-size: 20px;
`;

