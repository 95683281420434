import React from "react";
import styled from "styled-components";
import { ICard } from '../helpers/interfaces';
import MiniCard from './MiniCard';

interface IModalProps {
  discardDetails: Array<ICard>;
  discardFunction?: (cardID: string) => void;
  closeModal(): void;
  disabled: boolean;
}

const DiscardModal: React.FC<IModalProps> = (props) => {

  const discardClicked = (cardID: string) => {
    if (props.discardFunction) {
      props.discardFunction(cardID);
      props.closeModal();
    }
  }
  return (
    <>
      <ModalWrapper>
        <CardGrid>
          {props?.discardDetails?.map((card, index) => {
            return (
              <MiniCard
                card={card}
                click={() => discardClicked(card?.id)}
                disabled={props.disabled}
                key={index}
              />
            );
          })}
        </CardGrid>
      </ModalWrapper>
    </>
  )
};

export default DiscardModal;

const CardGrid = styled.div`
    display:grid;
    grid-template-columns: 200px;
`

const ModalWrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    max-height: 400px;
    border-radius: 5px;
    padding: 5px;
    overflow:auto;
    background-color: RGB(0,0,0,.5);
`;
