import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { io } from "socket.io-client";
import { ICard, Server, Trade, TradeDetail, Tutorial } from './helpers/interfaces';
import * as CONSTANTS from './helpers/constants';
import fetchAPI from './helpers/api';
import Hand from './components/Hand';
import Discard from './components/Discard';
import Misc from './components/Misc';
import NavBar from './components/NavBar';
import TopBar from './components/TopBar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PlayerList from './components/PlayerList';
import SnackBar from './components/SnackBar';
import TradeControl from './components/TradeControl';

interface Props extends RouteComponentProps<
  { myParamProp?: string }, // props.match.params.myParamProp
  any, // history
  { username?: string } // props.location.state.myStateProp
> {
  myNormalProp: boolean;
}

const Game: React.FC<Props> = (props) => {
  const [server, setServer] = useState<Server>();
  const [serverDetail, setServerDetail] = useState<Server>();
  const [trades, setTrades] = useState<Trade>();
  const [tradesDetail, setTradesDetail] = useState<Array<TradeDetail>>([]);
  const [cards, setCards] = useState<Array<ICard>>([]);
  const [decklist, setDecklist] = useState<String>("");
  const [tutorials, setTutorials] = useState<Array<Tutorial>>([]);
  const [discardDisplay, setDiscardDisplay] = useState<boolean>(true);
  const [appendix, setAppendix] = useState<Array<ICard>>([]);;

  useEffect(() => {
    fetchAPI(CONSTANTS.GAMESETTINGS_URL(1))
      .then((res: any) => {
        setDecklist(res[0].deckid);
        setDiscardDisplay(res[0].discardvisible);
      })
      .catch((err: any) => alert("internal error - retry:" + err));

    fetchAPI(CONSTANTS.TUTORIAL_URL())
      .then((res: any) => setTutorials(res.filter((tutorial: Tutorial) => tutorial.active === true)))
      .catch((err: any) => alert("internal error - retry:" + err));
  }, []);

  useEffect(() => {
    fetchAPI(CONSTANTS.CARDLIST_URL())
      .then((res: any) => setCards(res.filter((card: ICard) => card.deckid === decklist)))
      .catch((err: any) => alert("internal error - retry:" + err));
    console.log("fetching cards");
  }, [decklist]);

  useEffect(() => {
    setAppendix(cards.filter((card: ICard) => card.color === 'Appendix'));
  }, [cards]);

  useEffect(() => {
    if (server) {
      let tempServer = JSON.parse(JSON.stringify(server));
      console.log(tempServer);
      for (let i = 0; i < tempServer.players.length; i++) {
        const handArr = tempServer.players[i].cardsInHand.split(",");
        tempServer.players[i].cardDetails = [];
        for (let j = 0; j < handArr.length; j++) {
          const index = cards.findIndex((card: ICard) => card.id.toString() === handArr[j]);

          if (index !== -1) {
            tempServer.players[i].cardDetails.push({
              id: cards[index].id,
              title: cards[index].title,
              text: cards[index].text,
              color: cards[index].color,
            });
          };
        };
      };

      if (tempServer?.discard?.length > 0) {
        const discardArr = tempServer.discard.split(",");
        tempServer.discardDetails = [];
        for (let i = 0; i < discardArr.length; i++) {
          const index = cards.findIndex((card: ICard) => card.id.toString() === discardArr[i]);
          if (index !== -1) {
            tempServer.discardDetails.push({
              id: cards[index].id,
              title: cards[index].title,
              text: cards[index].text,
              color: cards[index].color,
            });
          };
        };
      };
      setServerDetail(tempServer);
    }
  }, [server, cards]);

  useEffect(() => {
    if (trades) {
      const tradesDetails = trades.tradesInProgress.map(trade => {
        const cardFromArr = trade.cardFrom.map(cardId => {
          const cardObj = cards.filter((card: ICard) => card.id === cardId);
          let cardDetails: ICard;
          if (cardObj.length > 0) {
            cardDetails = { id: cardObj[0].id, title: cardObj[0].title, text: cardObj[0].text, color: cardObj[0].color, deckid: cardObj[0].deckid }
          } else {
            cardDetails = { id: '', title: '', text: '', color: '', deckid: '' }
          }
          return cardDetails;
        });

        const cardToArr = trade.cardTo.map(cardId => {
          const cardObj = cards.filter((card: ICard) => card.id === cardId);
          let cardDetails: ICard;
          if (cardObj.length > 0) {
            cardDetails = { id: cardObj[0].id, title: cardObj[0].title, text: cardObj[0].text, color: cardObj[0].color, deckid: cardObj[0].deckid }
          } else {
            cardDetails = { id: '', title: '', text: '', color: '', deckid: '' }
          }
          return cardDetails;
        });

        return {
          cardListFrom: cardFromArr,
          cardListTo: cardToArr,
          userIdFrom: trade.idFrom,
          userIdTo: trade.idTo,
          firstAccept: trade.firstAccept,
        }
      });
      // console.log(tradesDetails)
      setTradesDetail(tradesDetails);
    }
  }, [trades, cards]);

  useEffect(() => {
    console.log("setting up socket.io");
    const socket = io(CONSTANTS.SERVER_URL);
    socket.on("ServerState", (data: Server) => {
      setServer(data);
    });

    socket.on("Trade", (data: Trade) => {
      setTrades(data);
    });

    return () => {
      socket.disconnect();
    }
  }, []);

  const CurrentPlayer = () => {
    if (serverDetail) {
      return (
        serverDetail.players.find(player => player.playerID === props.location.state.username)
      )
    }
  }

  return (
    <>
      <TradeControl
        username={props.location.state.username!}
        tradesDetail={tradesDetail!}
        serverDetail={serverDetail!}
      />
      <SnackBar />
      <TopBar
        appendix={appendix}
        tutorials={tutorials}
        time={serverDetail?.time}
      />
      <Router>
        <Switch>
          <Route path='/game' exact render={() => (
            <Hand username={props.location.state.username!} serverDetail={serverDetail!} tradesDetail={tradesDetail!} />
          )} />
          <Route path='/discard' render={() => (
            <Discard username={props.location.state.username!} serverDetail={serverDetail!} discardDisplay={discardDisplay} />
          )} />
          <Route path='/playerlist' component={() => (
            <PlayerList players={serverDetail?.players} />
          )} />
          <Route path='/profile' component={() => (
            <Misc
              currentPlayer={CurrentPlayer()}
              username={props.location.state.username!}
              serverDetail={serverDetail!}
              discardDisplay={discardDisplay}
            />
          )} />
        </Switch>
        <NavBar />
      </Router>
    </>
  );
};

export default Game;