import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import fetchAPI from './helpers/api';
import * as CONSTANTS from './helpers/constants';
import './Login.css'
import logo from "./assets/OOU_Logo_with_Texture.png"

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [leader, setLeader] = useState<boolean>(false);
  const history = useHistory();

  const addPlayer = async () => {
    const data = await fetchAPI(CONSTANTS.ADDPLAYER_URL(username, leader));
    console.log(data);
    history.push({
      pathname: "/game",
      state: { username: username }
    });
  };

  return (
    <div className="bg">
      <img src={logo} alt="logo" className="logo" />
      <Form>
        <Form.Group as={Row} controlId="username" inline="true" className="row justify-content-center">
          <Col xs="auto">
            <Form.Control
              size="sm"
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={e => setUsername(e.target.value.toLowerCase())}
            />
          </Col>
        </Form.Group>
        <Form.Group controlId="formBasicCheckbox" style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Check
            inline
            type="radio"
            label="Leader"
            style={{ color: 'gold', marginLeft: '-5px' }}
            name="cb"
            id="cb"
            checked={leader}
            onChange={e => setLeader(e.target.checked)}
          />
          <Form.Check
            inline
            type="radio"
            label="Follower"
            style={{ color: 'gold', marginRight: '10px' }}
            name="cb2"
            id="cb2"
            checked={!leader}
            onChange={e => setLeader(!e.target.checked)}
          />
        </Form.Group>
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={addPlayer} className="row justify-content-center" disabled={username === ""}>Play</Button>
        </Col>
      </Form>
    </div>
  );
};

export default Login;