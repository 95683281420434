import React from 'react'
import styled from 'styled-components';
import { ICard, Player } from '../helpers/interfaces';
import CultIcon from '../components/CultIcon';

interface Props {
  card: ICard | undefined;
  click: any | undefined;
  players?: Array<Player> | undefined;
  order?: number;
  disabled?: boolean;
}

const MiniCard: React.FC<Props> = (props) => {

  return (
    <MiniCardContainer style={{ backgroundColor: props.disabled ? 'grey' : 'white' }} onClick={!props.disabled ? props.click : () => { }} order={props.order}>
      <OOUText>
        {props?.card?.title}
      </OOUText>
      <IconContainer>
        <CultIcon color={props?.card?.color.toLowerCase()} iconSize={25} />
      </IconContainer>
    </MiniCardContainer>
  );
};

export default MiniCard;

const IconContainer = styled.div`
    height: 30px;
    width: 30px;
    margin-left: 5px;
`;

const OOUText = styled.div`
    margin: 0px 5px 0px 5px;
    font-weight:1000;
    font-size: 17px;
    letter-spacing: .85px;
    line-height: 20px;
    font-family: Poppins;
`;

interface IMiniCardContainer {
  order?: number;
}
const MiniCardContainer = styled.div<IMiniCardContainer>`
    display: flex;
    flex:1;
    align-items: center;
    border-radius: 5px;
    height: 75px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    margin: 5px;
    background-color: #FFFFFF;
    padding: 5px;
    justify-content: space-between;
    animation: dropIn .7s ease both;
    animation-delay: calc(${(props) => props.order!} * .05s);

    @keyframes dropIn{
        0%{transform: scale(.5);
            opacity:0}
        50%{transform:scale(1.1);
            opacity:1}
        100%{ transform: scale(1);
            opacity:1}
    }
`;