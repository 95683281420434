
import React from "react";
import styled from "styled-components";
import NamePlate from './NamePlate';
import { Player, TradeDetail } from '../helpers/interfaces';

interface IModalProps {
  //   isOpen: boolean;
  //   onCloseRequest(data: object): void;
  username: string;
  players: Array<Player> | undefined;
  tradesDetail: Array<TradeDetail>;
  tradeFunction?: (playerTo: string) => void;
  closeModal(): void;
}

const TradeModal: React.FC<IModalProps> = (props) => {

  const playersInTrade = props.tradesDetail.map((trade) => [trade.userIdFrom, trade.userIdTo]).flat();
  
  return (
    <>
      {/* <ModalBackgroundStyled onClick={props.onCloseRequest}/> */}
      <ModalWrapper>
        {props?.players?.filter((player: Player) => player.playerID !== props.username && !playersInTrade.includes(player.playerID))
          .map(player => {
            return (
              <NamePlate
                player={player}
                tradeFunction={props.tradeFunction}
                closeModal={props.closeModal}
                key={player.playerID}
              />
            );
          })}
      </ModalWrapper>
    </>
  )
};

export default TradeModal;

// const ModalBackgroundStyled = styled.div`
//   background-color: rgba(255,255,255,0.5);
//   backdrop-filter: blur(2px);
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   z-index: 1;
//   height:200%;
//   width:200%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

const ModalWrapper = styled.div`

    display:flex;
    flex-direction: column;
    align-items:center;
    background: #E5E5E5;
    border-radius: 4px;
    width: 100%
    /* overflow: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    min-width: 30rem;
    z-index: 2;
    height: 500px; */
`;
