import React, { useState } from 'react';
import CardContainer from './CardContainer';
import CardDetail from './CardDetail';
import Modal from 'react-bootstrap/Modal';
import { Server, TradeDetail, ICard } from '../helpers/interfaces';
import { PUTDISCARD_URL, INITIATETRADE_URL } from '../helpers/constants';
import fetchAPI from '../helpers/api';
import styled from 'styled-components';
import TradeModal from './TradeModal';
// import { SnackContext, SnackContextType } from '../helpers/SnackContext';

interface Props {
  username: string;
  serverDetail: Server;
  tradesDetail: Array<TradeDetail>;
}

const Hand: React.FC<Props> = (props) => {
  const [selectedCard, setSelectedCard] = useState<ICard>();
  const [cardModal, setCardModal] = useState<boolean>(false);
  const [tradeModalOpen, setTradeModalOpen] = React.useState<boolean>(false);

  const tradeInProgress = props.tradesDetail
    .map((trade) => [trade.userIdFrom, trade.userIdTo])
    .flat()
    .includes(props.username);

  const putDiscard = async () => {
    await fetchAPI(PUTDISCARD_URL(props.username, selectedCard!.id));
    setSelectedCard(undefined);
  }

  const tradeCard = async (playerTo: string) => {
    await fetchAPI(INITIATETRADE_URL(props.username, playerTo, selectedCard!.id));
    setSelectedCard(undefined);
    setTradeModalOpen(false);
  }

  const closeCardModal = () => {
    setCardModal(false);
  }

  return (
    <Wrapper>
      <Modal
        show={cardModal}
        onHide={() => {
          setCardModal(false);
          setSelectedCard(undefined);
        }}
        centered
      >
        <CardDetail
          card={selectedCard}
          players={props?.serverDetail?.players}
          discardFunction={putDiscard}
          closeModal={closeCardModal}
          tradeClicked={() => {
            setTradeModalOpen(true);
            setCardModal(false);
          }}
          tradeInProgress={tradeInProgress}
        />
      </Modal>
      <Modal
        show={tradeModalOpen}
        onHide={() => setTradeModalOpen(false)}
        centered
      >
        <TradeModal
          username={props.username}
          players={props?.serverDetail?.players}
          tradesDetail={props.tradesDetail}
          tradeFunction={tradeCard}
          closeModal={() => setTradeModalOpen(false)}
        />
      </Modal>
      <CardContainer username={props.username}
        serverDetail={props.serverDetail}
        handleSelectedCard={(card: ICard) => {
          setSelectedCard(card);
          setCardModal(true);
        }}
      />
    </Wrapper>
  );
};

export default Hand;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #E5E5E5;
  overflow: auto;
  display: column;
`;
