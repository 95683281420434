import React from "react";
import styled from "styled-components";
import { ICard, Server, TradeDetail } from '../helpers/interfaces';
import MiniCard from '../components/MiniCard';
import NamePlate from '../components/NamePlate'
import { CANCELTRADE_URL, UPDATETRADE_URL, FIRSTACCEPTTRADE_URL, TRADE_URL } from '../helpers/constants';
import fetchAPI from '../helpers/api';

interface IModalProps {
  username: string;
  serverDetail: Server;
  tradeDetail: TradeDetail | undefined;
  closeModal(): void;
}

const TradeInitiatedModal: React.FC<IModalProps> = (props) => {
  const maxTradeSize = 3;
  let otherPlayer: string | undefined = ''
  let cardNumInMyTrade = 0;
  let cardNumInOtherTrade = 0;

  if (props.tradeDetail?.userIdFrom === props.username) {
    otherPlayer = props.tradeDetail.userIdTo;
    // check cards in trade
    cardNumInMyTrade = props.tradeDetail.cardListFrom.length;
    cardNumInOtherTrade = props.tradeDetail.cardListTo.length;
  } else {
    otherPlayer = props.tradeDetail?.userIdFrom;
    // check cards in trade
    cardNumInOtherTrade = props.tradeDetail?.cardListFrom.length ?? 0;
    cardNumInMyTrade = props.tradeDetail?.cardListTo.length ?? 0;
  }

  // check if leader in the trade
  const isLeaderInTrade = props.serverDetail.players.find(p => p.playerID === props.username)?.leader === 'true'
    || props.serverDetail.players.find(p => p.playerID === otherPlayer)?.leader === 'true';

  // check cards in hand
  const cardNumInMyHand = props.serverDetail.players.find(p => p.playerID === props.username)?.cardDetails.length ?? 0;
  const cardNumInOtherHand = props.serverDetail.players.find(p => p.playerID === otherPlayer)?.cardDetails.length ?? 0;

  // check difference of cards
  const cardDiffInMyTrade = cardNumInOtherTrade - cardNumInMyTrade;
  const cardDiffInOtherTrade = cardNumInMyTrade - cardNumInOtherTrade;

  const disableTrade = cardNumInMyHand + cardDiffInMyTrade > maxTradeSize || cardNumInOtherHand + cardDiffInOtherTrade > maxTradeSize;

  const checkTradeBtnDisabled = () => {
    if (isLeaderInTrade) {
      return false;
    }
    else if (disableTrade || (props.tradeDetail?.firstAccept.accepted && props.tradeDetail?.firstAccept.user === props.username)) {
      return true;
    } else {
      return false;
    };
  };
  ////////

  const tradeCardSelected = async (card: ICard) => {
    const response = await fetchAPI(UPDATETRADE_URL(props.username, card.id));
    console.log(response);
    console.log("selected card", card.id);
  };

  const cancelTrade = async () => {
    const response = await fetchAPI(CANCELTRADE_URL(props.username));
    console.log(response);
  };

  const acceptTrade = async () => {
    if (props.tradeDetail && props.tradeDetail.firstAccept.accepted) {
      let routes: Promise<string>[] = [];

      for (let i = 0; i < props.tradeDetail.cardListFrom.length; i++) {
        routes = [...routes, fetchAPI(TRADE_URL(props.tradeDetail.userIdFrom, props.tradeDetail.userIdTo, props.tradeDetail.cardListFrom[i].id))];
      }
      for (let i = 0; i < props.tradeDetail.cardListTo.length; i++) {
        routes = [...routes, fetchAPI(TRADE_URL(props.tradeDetail.userIdTo, props.tradeDetail.userIdFrom, props.tradeDetail.cardListTo[i].id))];
      }

      const data = await Promise.all(routes)
      console.log(data);
    } else {
      const response = await fetchAPI(FIRSTACCEPTTRADE_URL(props.username));
      console.log(response);
    }
  };

  return (
    <>
      <ModalWrapper>
        <NamePlateContainer>
          <NamePlate
            player={props.serverDetail.players.find(player => player.playerID === otherPlayer)}
          />
        </NamePlateContainer>
        <DescriptionContainer>
          <CardArea>
            {props.tradeDetail && props.username === props.tradeDetail.userIdTo ?
              props.tradeDetail && props.tradeDetail.cardListFrom.map(() => <CardIcon style={{ backgroundColor: 'black' }} />) :
              props.tradeDetail && props.tradeDetail!.cardListTo.map(() => <CardIcon style={{ backgroundColor: 'black' }} />)}
          </CardArea>
          <CardArea>
            {props.tradeDetail && props.username === props.tradeDetail.userIdFrom ?
              props.tradeDetail && props.tradeDetail!.cardListFrom.map((card) => <CardIcon onClick={() => tradeCardSelected(card)}><OOUText style={{ color: "black", fontSize: 10 }}>{card.title}</OOUText></CardIcon>) :
              props.tradeDetail && props.tradeDetail!.cardListTo.map((card) => <CardIcon onClick={() => tradeCardSelected(card)}><OOUText style={{ color: "black", fontSize: 10 }}>{card.title}</OOUText></CardIcon>)}
          </CardArea>
        </DescriptionContainer>
        <NamePlateContainer>
          <NamePlate
            player={props.serverDetail.players.find(player => player.playerID === props.username)}
          />
        </NamePlateContainer>
        <CardGrid>
          {props.serverDetail.players.find((player) => player.playerID === props.username)
            ?.cardDetails.map((card: ICard, index: number) => (
              <MiniCard
                card={card}
                click={() => tradeCardSelected(card)}
                players={props.serverDetail?.players}
                order={index}
                disabled={cardNumInMyTrade >= maxTradeSize}
                key={index}
              />
            ))}
        </CardGrid>
        <ButtonArea>
          <OOUButton onClick={acceptTrade} disabled={checkTradeBtnDisabled()}>
            <OOUText>
              CONFIRM
            </OOUText>

          </OOUButton>
          <OOUButton onClick={() => {
            cancelTrade();
            props.closeModal();
          }}>
            <OOUText>
              CANCEL
            </OOUText>
          </OOUButton>
        </ButtonArea>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <OOUText style={{ color: "black" }}>
            {(props.tradeDetail?.firstAccept.user === undefined ? "" : ` ${props.tradeDetail?.firstAccept.user} confirmed`)}
          </OOUText>
        </div>
      </ModalWrapper>
    </>
  )
};

export default TradeInitiatedModal;

const ModalWrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    border-radius: 4px;
    max-height: 600px;
    background-color: white;
    width: 90%;
    height: 70vh;
`;

const CardGrid = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x:auto;
    height: 115px;
    background: linear-gradient(0deg, rgb(255, 255, 255) 10%, rgba(0, 0, 0,.25) 100%);

`;
const DescriptionContainer = styled.div`
    display: grid;
    //flex-direction:column;
    /* font-family: Poppins;
    font-weight: 500;
    font-size: 14px;*/
    justify-content:center;
    align-items: center;
    text-align:center;
    width: 100%; 
    grid-template-rows:1fr 1fr;
`;
const NamePlateContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex:1;
    max-height: 40px;
`;

const CardArea = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    padding: 5px;
`;

const CardIcon = styled.div`
    display: flex;
    width: 60px;
    height: 75px;
    background-color: lightgray;
    border-radius: 5px;
    margin: 0px 7px;
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;
`;

const OOUButton = styled.button`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color:#252222;
  border-radius: 10px;
  width: 150px;
  padding: 5px 0px;
  transition: all 1000ms ease-out;

  :active{
    transform: scale(.7);
  }
`;

const ButtonArea = styled.div`
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding: 5px 2rem;
`;

const OOUText = styled.div`
  color: white;
  font-weight: 700;
  letter-spacing: 1px;
  font-family:Poppins;
  font-size: 20px;
`;