import React, { useState, useContext, useCallback, useEffect } from 'react'
import { Server, TradeDetail } from '../helpers/interfaces';
import Modal from 'react-bootstrap/Modal';
import { SnackContextType, SnackContext } from '../helpers/SnackContext'
import { CANCELTRADE_URL, FIRSTACCEPTTRADE_URL, TRADE_URL } from '../helpers/constants';
import fetchAPI from '../helpers/api';
import TradeInitatedModal from './TradeInitiatedModal'

interface Props {
  username: string;
  tradesDetail: Array<TradeDetail>;
  serverDetail: Server;
}
const TradeControl: React.FC<Props> = (props) => {
  const { setSnack } = useContext<SnackContextType>(SnackContext);
  const [tradeModal, setTradeModal] = useState<boolean>(false);

  const cancelTrade = useCallback(async () => {
    const response = await fetchAPI(CANCELTRADE_URL(props.username!));
    console.log(response);
    //setCardModal(false);
    //resetTrade(); -----------TODO
    setSnack({
      display: false,
      message: "",
      interactable: false,
      onAccept: () => { },
      onClose: () => { }
    })
  }, [props.username, setSnack]);

  const firstAcceptTrade = useCallback(async () => {
    const response = await fetchAPI(FIRSTACCEPTTRADE_URL(props.username!));
    console.log(response);
    setSnack({
      display: false,
      message: "",
      interactable: false,
      onAccept: () => { },
      onClose: () => { }
    })
  }, [props.username, setSnack]);

  const confirmTrade = useCallback(async (trade: TradeDetail) => {
    let routes: Promise<string>[] = [];

    for (let i = 0; i < trade.cardListFrom.length; i++) {
      routes = [...routes, fetchAPI(TRADE_URL(trade.userIdFrom, trade.userIdTo, trade.cardListFrom[i].id))];
    }
    for (let i = 0; i < trade.cardListTo.length; i++) {
      routes = [...routes, fetchAPI(TRADE_URL(trade.userIdTo, trade.userIdFrom, trade.cardListTo[i].id))];
    }

    const data = await Promise.all(routes)
    console.log(data);

    setSnack({
      display: false,
      message: "",
      interactable: false,
      onAccept: () => { },
      onClose: () => { }
    })
  }, [setSnack]);

  useEffect(() => {
    // close the modal if either player cancels the trade
    if (-1 === props.tradesDetail.findIndex(trade => trade.userIdFrom === props.username || trade.userIdTo === props.username)) {
      setTradeModal(false);
    }
    if (!tradeModal) {
      console.log(`Trades in progress: `);
      console.log(props.tradesDetail);
      setSnack({
        display: false,
        message: "",
        interactable: false,
        onAccept: () => { },
        onClose: () => { }
      });

      for (let i = 0; i < props.tradesDetail.length; i++) {
        if (props.tradesDetail[i].userIdTo === props.username) {
          setSnack({
            display: true,
            message: `${props.tradesDetail[i].userIdFrom.toUpperCase()} wants to trade.`,
            interactable: true,
            onAccept: () => {
              setTradeModal(true);
              setSnack({
                display: false,
                message: "",
                interactable: false,
                onAccept: () => { },
                onClose: () => { }
              });
            },
            onClose: () => cancelTrade()
          });

        } else if (props.tradesDetail[i].userIdFrom === props.username) {
          if (props.tradesDetail[i].firstAccept.accepted) {
            setSnack({
              display: true,
              message: `${props.tradesDetail[i].userIdTo.toUpperCase()} is ready to confirm the trade.`,
              interactable: true,
              onAccept: () => {
                setTradeModal(true);
                setSnack({
                  display: false,
                  message: "",
                  interactable: false,
                  onAccept: () => { },
                  onClose: () => { }
                })
                //confirmTrade(props.tradesDetail[i])
              },
              onClose: () => cancelTrade()
            });
          } else {
            setSnack({
              display: true,
              message: `Trade requested with ${props.tradesDetail[i].userIdTo.toUpperCase()}.`,
              interactable: true,
              onAccept: () => {
                setTradeModal(true);
                setSnack({
                  display: false,
                  message: "",
                  interactable: false,
                  onAccept: () => { },
                  onClose: () => { }
                })
              },
              onClose: () => cancelTrade()
            });
          }
        }
      }
    }
  }, [props.tradesDetail, props.username, setSnack, cancelTrade, firstAcceptTrade, confirmTrade, tradeModal])

  return (
    <Modal
      show={tradeModal}
      enforceFocus={true}
      onHide={() => { }}
      centered
    >
      <TradeInitatedModal
        username={props.username}
        tradeDetail={props.tradesDetail.find(trade => trade.userIdFrom === props.username || trade.userIdTo === props.username)}
        serverDetail={props.serverDetail}
        closeModal={()=> setTradeModal(false)}
      />
    </Modal>
  );
};

export default TradeControl;