import React from 'react'
import styled from 'styled-components';
import useColor from '../hooks/useColor';
import CultIcon from '../components/CultIcon';

interface Props {
    color: string;
    colorChangeFunction: (playerTo: string) => void;
    closeModal(): void;
}

const ColorPlate: React.FC<Props> = (props) => {

    const [, primary,] = useColor(props?.color.toLowerCase());

    const colorClicked = () => {
        if (props.colorChangeFunction) {
            props.colorChangeFunction(props.color);
            props.closeModal();
        }
    }

    return (
        <>
            {/* put on trade clicked on wrapper */}
            <Wrapper
                onClick={colorClicked}
                style={{ backgroundColor: primary }}
            >
                <CultIcon
                    black
                    color={props?.color.toLowerCase()}
                    iconSize={25}
                />
                <TextContainer>
                    <ColorText>
                        {props.color.toUpperCase()}
                    </ColorText>
                </TextContainer>
            </Wrapper>
        </>
    );
};

export default ColorPlate;

const Wrapper = styled.div`
    display: flex;
    margin: 5px;
    width: 70%;
    border-radius: 5px;
    padding-left: 15px;
    min-width: 150px;
`
const TextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
    display: flex;
    height: 35px;
    width: 100%;
`
const ColorText = styled.div`
    color: white;
    font-weight: 600;
    letter-spacing: .6px;
`