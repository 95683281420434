
function useColor(color: any){
    let light: any ='';
    let primary: any = '';
    let dark:any = '';

    switch(color){
        case 'red':
            light = "#E9656A";
            primary = "#C62127";
            dark = "#721215";
            break;
        case 'orange':
            light = "#F3A26D";
            primary = "#E16F26";
            dark = "#AE571E";
            break;
        case 'yellow':
            light = "#FEE074";
            primary = "#F4C416";
            dark = "#BB9300";
            break;
        case 'green':
            light = "#7EBF7D";
            primary = "#449343";
            dark = "#2D682C";
            break;
        case 'blue':
            light = "#5B86CF";
            primary = "#2856A5";
            dark = "#17376C";
            break;
        case 'purple':
            light = "#AF62BB";
            primary = "#602869";
            dark = "#491851";
            break;
        default:
            light = "#E0E0E0";
            primary = "#FFFFFF";
            dark = "#252222";
            break;
    }

    // if (color === "red"){

    //     light = "#E9656A"
    //     primary = "#C62127";
    //     dark = "#721215";

    // }

    return[light, primary, dark]
}

export default useColor;