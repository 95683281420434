import React from "react";
import styled from "styled-components";


interface IModalProps {
  // discardDetails: Array<ICard>;
  // discardFunction?: (cardID:string) => void;
  openAppendix(): void;
  openTutorial(): void;
  openCredits(): void;
}

const InfoButtonsModal: React.FC<IModalProps> = (props) => {
  return (
    <>
      <ModalWrapper>
        <InfoButton onClick={props.openTutorial}>
          <InfoText>
            RULES
          </InfoText>
          <InfoText>
            OVERVIEW
          </InfoText>
        </InfoButton>
        <InfoButton onClick={props.openAppendix}>
          <InfoText>
            CARD
          </InfoText>
          <InfoText>
            REFERNCE
          </InfoText>
        </InfoButton>
        <InfoButton onClick={props.openCredits}>
          <InfoText>
            GAME
          </InfoText>
          <InfoText>
            CREDITS
          </InfoText>
        </InfoButton>
      </ModalWrapper>
    </>
  )
};

export default InfoButtonsModal;


const ModalWrapper = styled.div`
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-around;
    border-radius: 4px;
    background-color: transparent;
    width: 100%;
    flex-wrap: wrap;
`;
const InfoButton = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 150px;
    border-radius: 10px;
    margin: 5px;
`;
const InfoText = styled.div`
    font-weight: 700;
    font-size: 17px;
    letter-spacing: 1px;
    font-family: Poppins;
    line-height: 20px;
`;
