import React from 'react'
import styled from 'styled-components';
import useColor from '../hooks/useColor';

interface Props {
    text: string | undefined;
    onpress: () => void;
    color: string | undefined;
}

const OOUButton: React.FC<Props> = (props) => {

    const [, primary,] = useColor(props?.color?.toLowerCase());

    return (
        <>
            <ButtonStyles
                style={{ backgroundColor: primary }}
                onClick={props.onpress}
            >
                <OOUText style={{ color: 'white', fontWeight: 700 }}>
                    {props?.text}
                </OOUText>
            </ButtonStyles>
        </>
    );
};

export default OOUButton;

const OOUText = styled.p`
    margin-bottom: 0px;
`;

const ButtonStyles = styled.div`
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 38px;
    max-width: 112px;
`;