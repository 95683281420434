
import React from "react";
import styled from "styled-components";
import NamePlate from './NamePlate';
import { Player } from '../helpers/interfaces';

interface IModalProps {
  players: Array<Player> | undefined;
}

const PlayerList: React.FC<IModalProps> = (props) => {
  
  return (
    <>
        <Wrapper>
            <ListContainer>
            {props?.players?.map((player, index)=>{
                return(
                    <NamePlate 
                        player={player}
                        order={index}
                        key={index}
                    />
                );
            })}
            </ListContainer>
        </Wrapper>
    </>
    )
};

export default PlayerList;

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    background: #E5E5E5;
    border-radius: 4px;
    width: 100%;
    height: calc(100vh - 120px);
`;

const ListContainer = styled.div`
    margin-top: 30px;
    display: flex;
    width:100%;
    flex-direction: column;
    align-items: center;
    overflow: auto;
`;