import React from "react";
import styled from "styled-components";
import { ICard } from '../helpers/interfaces';
// import MiniCard from '../components/MiniCard';

interface IModalProps {
    appendix: Array<ICard>;
    //closeModal() : void;
}

const AppendixModal: React.FC<IModalProps> = (props) => {

    const [selectedCard, setSelectedCard] = React.useState<ICard>();

    return (
        <>
            <ModalWrapper>
                <CardGrid>
                    {props.appendix.map((card) => {
                        return (
                            <CardNameContainer
                                onClick={() => setSelectedCard(card)}
                            >
                                {card.title}
                            </CardNameContainer>)
                    })}
                </CardGrid>
                <DescriptionContainer>
                    <DCTitle>
                        {selectedCard?.title}
                    </DCTitle>
                    <NewDivider style={{ display: selectedCard === undefined ? "none" : "flex" }} />
                    {selectedCard?.text}
                </DescriptionContainer>
            </ModalWrapper>
        </>
    )
};

export default AppendixModal;

const ModalWrapper = styled.div`
    display:flex;
    flex-direction: row;
    align-items:flex-start;
    border-radius: 4px;
    max-height: 600px;
    background-color: white;
    width: 90%;
    height: 70vh;
`;

const CardGrid = styled.div`
    display: flex;
    flex-direction:column;
    overflow-y:auto;
    height: 100%;
    overflow-x:hidden;
    align-items: flex-start;
    width:40%;
    padding-right: 10px;
    background: linear-gradient(0deg, rgb(255, 255, 255) 10%, rgba(0, 0, 0,.25) 100%);
`;
const DescriptionContainer = styled.div`
    display: flex;
    flex-direction:column;
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    justify-content:center;
    text-align:center;
    margin: 10px 0px 0px 10px;
    width:60%;
`
const CardNameContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height:60px;
    width: 95%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    margin: 5px;
    background-color: #FFFFFF;
    padding: 0px 10px 0px 10px;
    font-weight:500;
    font-size: 15px;
    letter-spacing: .75px;
    line-height: 18px;
    font-family: Poppins;
    text-align:center;
    flex-shrink: 0;
`
const DCTitle = styled.div`
    display:flex;
    justify-content: center;
    font-weight:800;
    font-size: 18px;
    letter-spacing: .75px;
    line-height: 18px;
    font-family: Poppins;
    width:100%;
    margin-bottom: 5px;
`;
const NewDivider = styled.div`
    width: 85%;
    margin: 5px 0;
    height: 2px;
    align-self: center;
    background-color: black;
`;