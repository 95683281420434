import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Login from './Login';
import Game from './Game';
import { SnackProvider } from './helpers/SnackContext';

function App() {

  return (
    <SnackProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/game" component={Game} />
      </Switch>
    </BrowserRouter>
    </SnackProvider>
  );
}

export default App;
