import React from "react"; 
import styled from "styled-components";

const CreditsModal: React.FC = () => (
  <>
    <ModalWrapper>
      <TitleTextContainer>
        Credits
      </TitleTextContainer>
      <OOUDivider />
      <BodyTextContainer>
        One of Us was designed by Tidbit Games and the app was built by NiceNux Studios.
      </BodyTextContainer>
      <OOUDivider />
      <BodyTextContainer>
        <a href={"https://www.tidbitgames.com/"} target="_blank" rel="noreferrer noopener">https://www.tidbitgames.com</a>
      </BodyTextContainer>
      <BodyTextContainer>
        <a href={"https://www.nicenux.com/"} target="_blank" rel="noreferrer noopener">https://www.nicenux.com</a>
      </BodyTextContainer>
      <OOUDivider />
      <BodyTextContainer>
        Build: 0.1.3c
      </BodyTextContainer>
    </ModalWrapper>
  </>
);

export default CreditsModal;

const ModalWrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    border-radius: 4px;
    width: 90%;
    background-color: whitesmoke;
    padding: 5px 10px;
`;

const TitleTextContainer = styled.div`
    font-family:Poppins;
    font-weight: 1000;
    font-size: 19px;
    letter-spacing: 1px;
    width: 100%;
    display:flex;
    justify-content: center;
`

const BodyTextContainer = styled.div`
    font-family:Poppins;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: .65px;
    width: 80%;
    display:flex;
    justify-content: center;
    padding: 0px 5px;
    text-align: center;
    margin: 0px 0px 5px 0px;
`
const OOUDivider = styled.div`
    width: 85%;
    margin: 5px 0;
    height: 2px;
    align-self: center;
    background-color: black;
    margin: 0px 0px 10px 0px;
`;