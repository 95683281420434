import React from "react";
import styled from "styled-components";
import { Tutorial } from '../helpers/interfaces';


interface IModalProps {
    tutorials: Array<Tutorial>;
    closeModal(): void;
}

const TutorialModal: React.FC<IModalProps> = (props) => {
    const currentTutorialIndex = React.useRef(-1);
    const [tutorialTitle, setTutorialTitle] = React.useState<string>("Welcome to the Tutorial");
    const [tutorialText, setTutorialText] = React.useState<string>("Click outside the window to exit the tutorial at any time");

    function onTutorialClicked(direction: "next" | "previous") {
        if (direction === 'next') {
        currentTutorialIndex.current += 1;
        } else {
        currentTutorialIndex.current -= 1;
        }

        if (currentTutorialIndex.current === props.tutorials.length) {
            props.closeModal();
        } else {
        const currentTutorial: Tutorial = props.tutorials[currentTutorialIndex.current];
        setTutorialTitle(currentTutorial.tutorialtitle);
        setTutorialText(currentTutorial.tutorialtext);
        }
    }

    return (
        <>
            <ModalWrapper>
                <TitleTextContainer>
                    {tutorialTitle}
                </TitleTextContainer>
                <OOUDivider/>
                <TutorialTextContainer>
                    {tutorialText}
                </TutorialTextContainer>
                <ButtonContainer>
                    {currentTutorialIndex.current > 0 && <OOUButton onClick={() => onTutorialClicked('previous')}>Previous</OOUButton>}
                    <OOUButton onClick={() => onTutorialClicked('next')}>
                        {currentTutorialIndex.current === props.tutorials.length - 1 ? 'Close' : 'Next'}
                    </OOUButton>
                </ButtonContainer>
            </ModalWrapper>
        </>
    )
};

export default TutorialModal;


const ModalWrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    border-radius: 4px;
    width: 90%;
    background-color: whitesmoke;
    padding: 5px 10px;
`;

const TitleTextContainer = styled.div`
    font-family:Poppins;
    font-weight: 1000;
    font-size: 19px;
    letter-spacing: 1px;
    width: 100%;
    display:flex;
    justify-content: center;
`

const TutorialTextContainer = styled.div`
    font-family:Poppins;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: .65px;
    width: 100%;
    display:flex;
    justify-content: center;
    padding: 0px 5px;
`
const ButtonContainer = styled.div`
    display:flex;
    justify-content: space-around;
    margin: 20px 0px 0px 0px;
    width: 100%;
    height: 40px;
`
const OOUButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    font-family:Poppins;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: .7px;
    height: 35px;
    width: 100px;
    border-radius: 4px;
`
const OOUDivider = styled.div`
    width: 85%;
    margin: 5px 0;
    height: 2px;
    align-self: center;
    background-color: black;
    margin: 0px 0px 10px 0px;
`;